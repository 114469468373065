import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 328px;
  gap: 24px;
  min-height: 328px;

  ${p => p.theme.mediaQueries.mobile} {
    min-height: 312px;
    display: grid;
    width: max-content;
    min-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(312px, calc(50vw - 28px)));
    padding-inline: 16px;
  }
`

export const ScrollWrapper = styled.section`
  ${p => p.theme.mediaQueries.mobile} {
    overflow-x: auto;
    margin-inline: -16px;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
`
